// apiUtils.js


async function makeApiRequest(endpoint, method = 'GET', data = null, isFileUpload = false) {
    let baseUrl;

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        baseUrl = 'http://localhost:8081';
    } else {
        baseUrl = 'https://api.productimagesai.com';
    }

    const requestOptions = {
        method,
        credentials: 'include',
        headers: {}
    };

    if (data && (method === 'POST' || method === 'PUT' || method === 'PATCH' || method === 'DELETE')) {
        if (!isFileUpload) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(data);
        } else {
            requestOptions.body = data;  // Assuming data is a FormData object for file uploads
        }
    }

    try {
        const response = await fetch(`${baseUrl}/${endpoint}`, requestOptions);

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(JSON.stringify(errorResponse.detail) || 'Request failed');
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('API request error:', error);
        throw error;
    }
}



// async function makeApiRequest(endpoint, method = 'GET', data = null, isFileUpload = false) {

//     let baseUrl;

//     if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
//         console.log("Running on localhost");
//         baseUrl = 'http://localhost:8081';
//     } else {
//         // Running with a public IP address or domain
//         baseUrl = 'https://api.productimagesai.com'; // Replace "example.com" with your publicly accessible domain or IP address
//     }

//     const requestOptions = {
//         method,
//         headers: {
//             'Content-Type': 'application/json',
//             // Add any other headers you might need, e.g., authorization tokens
//         },
//         credentials: 'include', // This enables sending cookies with the request
//         body: data,
//     };

//     if (!isFileUpload) {
//         requestOptions.headers['Content-Type'] = 'application/json';
//         requestOptions.body = JSON.stringify(data);
//     }

//     try {
//         const response = await fetch(`${baseUrl}/${endpoint}`, requestOptions);

//         if (!response.ok) {
//             const errorResponse = await response.json();
//             throw new Error(JSON.stringify(errorResponse.detail) || 'Request failed');
//         }

//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         // You can handle different types of errors here
//         console.error('API request error:', error);
//         throw error;
//     }
// }

export default makeApiRequest;
