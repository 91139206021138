import Cookies from 'js-cookie';

function isAuthenticated() {
  // Retrieve the value of the 'piaia' cookie
  const piaia = Cookies.get('piaia');

  // Return true if the 'piaia' cookie exists and is not empty, false otherwise
  return !!piaia;
}

export default isAuthenticated;
