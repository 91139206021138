import React, { useState, useEffect } from 'react';
import Navigation from './Navigation.js'
import makeApiRequest from './util/diffute.js';
import ProjectsList from './components/ProjectsList.js'
import EmptyProjects from './components/EmptyProjects.js';

export default function Projects() {

    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await makeApiRequest('projects');
                setProjects(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    return (
        <div>
            <Navigation />
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900"></span>
                </div>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    <div>
                        {isLoading ? (
                            // Render skeleton loader while loading
                            <ProjectsListSkeleton />
                        ) : (
                            // Conditionally render EmptyProjects or ProjectsList based on data
                            projects.length === 0 ? <EmptyProjects /> : <ProjectsList projects={projects.projects} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

// Skeleton loader for ProjectsList
function ProjectsListSkeleton() {
    return (
        <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-700 h-10 w-10" />
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-700 rounded w-20" />
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-700 rounded col-span-2" />
                            <div className="h-2 bg-slate-700 rounded col-span-1" />
                        </div>
                        <div className="h-2 bg-slate-700 rounded w-24" />
                    </div>
                </div>
            </div>
        </div>
    );
}
