import React, { useState, useEffect } from 'react';
import Navigation from './Navigation.js'
import makeApiRequest from './util/diffute.js';
import ProjectsList from './components/ProjectsList.js'
import EmptyProjects from './components/EmptyProjects.js';
import { useParams } from 'react-router-dom';


import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    ChartBarSquareIcon,
    Cog6ToothIcon,
    FolderIcon,
    GlobeAltIcon,
    ServerIcon,
    SignalIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

const teams = [
    { id: 1, name: 'Planetaria', href: '#', initial: 'P', current: false },
    { id: 2, name: 'Protocol', href: '#', initial: 'P', current: false },
    { id: 3, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
]


const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
const activityItems = [
    {
        user: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: '2d89f0c8',
        branch: 'main',
        status: 'Completed',
        duration: '25s',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    // More items...
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Project() {

    const [project, setProject] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { project_id } = useParams();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await makeApiRequest(`project/${project_id}`);
                setProject(response);
                console.log("Called setProject: " + JSON.stringify(project));
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [project_id]);

    const secondaryNavigation = [
        { name: 'Overview', href: `/project/${project_id}`, current: true },
        { name: 'Media', href: `/project/${project_id}/media`, current: false },
    ]

    const stats = [
        { name: 'AI Jobs', value: project && project.jobs ? project.jobs.length : '0' },
        { name: 'Training Images', value: project && project.media ? project.media.length : '0' },
        // { name: 'Number of servers', value: '3' },
        // { name: 'Success rate', value: '98.5%' },
    ]

    return (
        <div>
            <Navigation />
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900"></span>
                </div>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    <div>
                        {isLoading ? (
                            // Render skeleton loader while loading
                            <ProjectsListSkeleton />
                        ) : (
                            // Conditionally render EmptyProjects or ProjectsList based on data
                            <main>
                                <header>
                                    {/* Secondary navigation */}
                                    <nav className="flex overflow-x-auto border-b border-white/10 py-4">
                                        <ul
                                            role="list"
                                            className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
                                        >
                                            {secondaryNavigation.map((item) => (
                                                <li key={item.name}>
                                                    <a href={item.href} className={item.current ? 'text-indigo-400' : ''}>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                    {/* Heading */}
                                    <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
                                        <div>
                                            <div className="flex items-center gap-x-3">
                                                {/* <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                                                    <div className="h-2 w-2 rounded-full bg-current" />
                                                </div> */}
                                                <h1 className="flex gap-x-3 text-base leading-7">
                                                    <span className="font-semibold text-black">{project.project}</span>
                                                    <span className="text-gray-600">/</span>
                                                    <span className="font-semibold text-black">{project.photos_description}</span>
                                                </h1>
                                            </div>
                                        </div>
                                        {/* <div className="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                                            Production
                                        </div> */}
                                    </div>

                                    {/* Stats */}
                                    {project && project.jobs && (
                                        <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-4">
                                            {stats.map((stat, statIdx) => (
                                                <div
                                                    key={stat.name}
                                                    className={classNames(
                                                        statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                                                        'border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8'
                                                    )}
                                                >
                                                    <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
                                                    <p className="mt-2 flex items-baseline gap-x-2">
                                                        <span className="text-4xl font-semibold tracking-tight text-black">{stat.value}</span>
                                                        {stat.unit ? <span className="text-sm text-gray-400">{stat.unit}</span> : null}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </header>

                                {/* Activity list */}
                                <div className="border-t border-white/10 pt-11">
                                    <h2 className="px-4 text-base font-semibold leading-7 text-black sm:px-6 lg:px-8">Jobs</h2>
                                    <table className="mt-6 w-full whitespace-nowrap text-left">
                                        <colgroup>
                                            <col className="w-full sm:w-4/12" />
                                            <col className="lg:w-4/12" />
                                            <col className="lg:w-2/12" />
                                            <col className="lg:w-1/12" />
                                            <col className="lg:w-1/12" />
                                        </colgroup>
                                        <thead className="border-b border-white/10 text-sm leading-6 text-black">
                                            <tr>
                                                <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                                                    User
                                                </th>
                                                <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                                                    Commit
                                                </th>
                                                <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
                                                    Status
                                                </th>
                                                <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                                                    Duration
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                                                >
                                                    Deployed at
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-white/5">
                                            {project.jobs.map((item) => (
                                                <tr key={item.commit}>
                                                    <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                                                        <div className="flex items-center gap-x-4">
                                                            <img src={item.user.imageUrl} alt="" className="h-8 w-8 rounded-full bg-gray-800" />
                                                            <div className="truncate text-sm font-medium leading-6 text-black">{item.user.name}</div>
                                                        </div>
                                                    </td>
                                                    <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                                        <div className="flex gap-x-3">
                                                            <div className="font-mono text-sm leading-6 text-gray-400">{item.commit}</div>
                                                            <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                                                {item.branch}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                                                        <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                            <time className="text-gray-400 sm:hidden" dateTime={item.dateTime}>
                                                                {item.date}
                                                            </time>
                                                            <div className={classNames(statuses[item.status], 'flex-none rounded-full p-1')}>
                                                                <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                            </div>
                                                            <div className="hidden text-black sm:block">{item.status}</div>
                                                        </div>
                                                    </td>
                                                    <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                                                        {item.duration}
                                                    </td>
                                                    <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                                                        <time dateTime={item.dateTime}>{item.date}</time>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </main>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

// Skeleton loader for ProjectsList
function ProjectsListSkeleton() {
    return (
        <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-700 h-10 w-10" />
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-700 rounded w-20" />
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-700 rounded col-span-2" />
                            <div className="h-2 bg-slate-700 rounded col-span-1" />
                        </div>
                        <div className="h-2 bg-slate-700 rounded w-24" />
                    </div>
                </div>
            </div>
        </div>
    );
}
