import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import makeApiRequest from './util/diffute.js';

export default function DeleteFile({ isOpen, isDeleting, setIsDeleting, onCancel, onDelete, onDeleteCompleted, project_id, fileUrl, itemsToDelete, selectedItems, setSelectedItems, isMultiDelete }) {
    console.log(selectedItems);

    const [open, setOpen] = useState(true)
    const [media, setMedia] = useState([]);
    const [error, setError] = useState(null);
    const [fileToDelete, setFileToDelete] = useState(null);

    const cancelButtonRef = useRef(null);

    const handleDelete = async () => {
        setIsDeleting(true);

        let itemsToDeletePayload = [];
        if (isMultiDelete) {
            console.log("itemsToDelete: " + itemsToDelete);
            // In multi-delete mode, use the itemsToDelete directly
            itemsToDeletePayload = itemsToDelete;
        } else {
            // In single-delete mode, delete only the current item
            itemsToDeletePayload = [fileUrl];
        }

        try {
            // Prepare the payload
            const payload = { keys: itemsToDeletePayload };
            console.log(payload);
            // Send the DELETE request to the server
            await makeApiRequest(`project/${project_id}/media`, 'DELETE', payload);
            // ... existing code ...
            onDeleteCompleted();
        } catch (err) {
            setError(err.message);
        }

        setIsDeleting(false);
    };



    // const handleDeleteFile = async () => {
    //     try {
    //         const payload = { keys: [fileUrl] };
    //         await makeApiRequest(`project/${project_id}/media`, 'DELETE', payload);

    //         // Close the modal
    //         setOpen(false);

    //         // Call the onDeleteCompleted callback to trigger parent's useEffect
    //         onDeleteCompleted();

    //     } catch (err) {
    //         setError(err.message);
    //     }
    // };

    // const handleDeleteFile = async (project_id, fileUrl) => {
    //     try {
    //         const payload = { keys: [fileUrl] };
    //         await makeApiRequest(`project/${project_id}/media`, 'DELETE', payload);

    //         // Update your state or perform other necessary actions
    //         // setMedia(prevMedia => prevMedia.filter(item => item.url !== fileUrl));
    //     } catch (err) {
    //         setError(err.message);
    //     }
    // };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Delete image
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {isMultiDelete ?
                                                        'Are you sure you want to delete the selected files?' :
                                                        `Are you sure you want to delete ${fileUrl.split('/').pop()}?`
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={handleDelete}
                                    >
                                        {isDeleting ? (
                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        ) : null}
                                        {isDeleting ? "Processing..." : "Yes, Delete"}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={onCancel}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>

                                    {/* {fileToDelete === fileUrl ? (
                                        <DeleteFile
                                            isOpen={true}
                                            onCancel={() => setFileToDelete(null)}
                                            onDelete={() => {
                                                handleDeleteFile(fileToDelete);
                                                setFileToDelete(null);
                                            }}
                                        />
                                    ) : (
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    )} */}



                                    {/* <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                    >
                                        Yes, Delete
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button> */}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
