import { ChevronRightIcon } from '@heroicons/react/20/solid'

export default function ProjectsList({ projects }) {

    const renderSkeletonLoader = () => (
        <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-700 h-10 w-10" />
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-700 rounded w-20" />
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-700 rounded col-span-2" />
                            <div className="h-2 bg-slate-700 rounded col-span-1" />
                        </div>
                        <div className="h-2 bg-slate-700 rounded w-24" />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <ul role="list" className="divide-y divide-gray-100">
                {projects.length > 0 ? (
                    projects.map((project) => (
                        <li key={project.project_id} className="relative flex justify-between gap-x-6 py-5">
                            <div className="flex min-w-0 gap-x-4">
                                <div className="min-w-0 flex-auto">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">
                                        <a href={'/project/' + project.project_id}>
                                            <span className="absolute inset-x-0 -top-px bottom-0" />
                                            {project.project}
                                        </a>
                                    </p>
                                    <p className="mt-1 flex text-xs leading-5 text-gray-500">

                                    </p>
                                </div>
                            </div>
                            <div className="flex shrink-0 items-center gap-x-4">
                                <div className="hidden sm:flex sm:flex-col sm:items-end">
                                    <p className="text-sm leading-6 text-gray-900">{project.role}</p>
                                    {project.lastSeen ? (
                                        <p className="mt-1 text-xs leading-5 text-gray-500">
                                            Last seen <time dateTime={project.lastSeenDateTime}>{project.lastSeen}</time>
                                        </p>
                                    ) : (
                                        <div className="mt-1 flex items-center gap-x-1.5">
                                            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                                <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                            </div>
                                            <p className="text-xs leading-5 text-gray-500">Online</p>
                                        </div>
                                    )}
                                </div>
                                <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            </div>
                        </li>
                    ))
                ) : (
                    // Render the skeleton loader if no projects are available
                    renderSkeletonLoader()
                )}
            </ul>
        </div>
    );
}
